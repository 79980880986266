// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

.actionBtn {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
}
.actionBtn button {
    /* SCSS */
    background: rgb(94 53 177);
    align-items: center;
    appearance: none;
    border-radius: 4px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0 2px 2px 0, rgba(0, 0, 0, 0.12) 0 1px 5px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    height: 36px;
    justify-content: center;
    letter-spacing: 0.0892857em;
    line-height: normal;
    min-width: 64px;
    outline: none;
    overflow: visible;
    padding: 0 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    will-change: transform, opacity;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0, rgba(0, 0, 0, 0.12) 0 1px 10px 0;
    }

    &:focus {
        box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0, rgba(0, 0, 0, 0.12) 0 1px 10px 0;
    }

    &:active {
        box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0 8px 10px 1px, rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
        background: #a46bf5;
    }
}
@media screen and (max-width: 768px) {
    .pos_btn_bill {
        width: 100%;
        // flex-direction: column;
    }
    .pos_btn_comp {
        width: 100%;
        flex-direction: column-reverse;
    }
    .mainBtns {
        width: 100%;
    }
    // .pos_btn_comp .smallBtns {
    //     width: 200%;
    // }
}

// purchaseFooter
.postable .css-wop1k0-MuiDataGrid-footerContainer {
    height: 0;
    min-height: 0 !important;
}
.header-style {
    background-color: purple;
}
.productTable .css-1bbo6z-MuiTableCell-root,
.productTable .css-5yopn7-MuiTableCell-root {
    padding: 3px !important;
}
.datepick input {
    padding: 10px 14px;

    width: 100%;
}

.calculator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    background: rgb(222, 202, 242);
}

.calculator-display {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 10px;
    text-align: right;
    width: 100%;
}

.calculator-keypad {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
}

.calculator-key {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    transition: all 0.2s ease;
}

.calculator-key:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}

.calculator-key:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    transform: translateY(2px);
}
#DatepickPur .css-mn1mr4-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 14px !important;
}
#DatepickPur .css-1xhypcz-MuiStack-root {
    padding: 0;
}
#purchaseTable input {
    padding: 5px 5px !important;
    text-align: center;
}
#purchaseTable .css-190ni6f-MuiTableCell-root,
#purchaseTable .css-1xh7ve2-MuiTableCell-root {
    padding: 5px !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
